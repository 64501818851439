// CHANGE: 市町村静的データの配列と都道府県静的データの配列をここで静的に定義していたが、S3の一元化データを使用するよう変更

// 市町村コードのユーティリティクラス
class municipalCodeUtil {
	/**
	 * 都道府県コードを扱うユーティリティクラスのコンストラクタ
	 * @param {Array} munocipal S3の一元化データオブジェクト配列
	 */
	constructor (munocipal) {
		this.MUNICIPAL_ARRAY = munocipal
		this.PREFECTURES_ARRAY = munocipal.map(item => {
			return { 'code': item.prefecture_code, 'name': item.prefecture_name }
		})
	}

	/**
	 * 都道府県の一覧を返す
	 * @return {Array}} 都道府県のオブジェクト配列
	 */
	getPrefecturesList () {
		return this.PREFECTURES_ARRAY
	}

	/**
	 * 都道府県コードに結びついた市町村一覧を返す
	 * @param {String} code 都道府県コード(01～47)
	 * @return {Array}} 市町村のオブジェクト配列
	 */
	getMunicipalList (code) {
		return this.MUNICIPAL_ARRAY[code - 1]	// -1することで数値にコンバート
	}

	/**
	 * 市町村コードに結びついた市町村データを返す
	 * @param {String} code 市町村コード
	 * @return {Object}} 市町村のオブジェクト
	 */
	getMunicipalData (code) {
		// NOTE: 市町村コードの先頭2文字が都道府県コードとなる
		const prefectureCode = parseInt(code.slice(0, 2))
		const prefectureData = this.PREFECTURES_ARRAY[prefectureCode - 1]
		const municipalObj = this.MUNICIPAL_ARRAY[prefectureCode - 1]
		const municipalData = municipalObj.municipals.find(o => o.code === code)
		// NOTE: 取得した市町村データに都道府県コードと都道府県名を付与して返す
		municipalData.prefecture_code = municipalObj.prefecture_code
		municipalData.prefecture_name = prefectureData.name
		return municipalData
	}
}

export default municipalCodeUtil
