import { render, staticRenderFns } from "./PointRegistration.vue?vue&type=template&id=afafbc24&scoped=true&"
import script from "./PointRegistration.vue?vue&type=script&lang=js&"
export * from "./PointRegistration.vue?vue&type=script&lang=js&"
import style0 from "./PointRegistration.vue?vue&type=style&index=0&id=afafbc24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afafbc24",
  null
  
)

export default component.exports