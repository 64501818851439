<template>
<!-- TODO: OnsenUiのStack navigationを使用するように変更 -->
<!-- TODO: Stack navigationを使用するためページコンポーネントを都道府県と市町村で分ける -->
<ons-page class="point-registration">
	<!-- ローディング -->
	<transition name="fade">
		<redraw-progress v-if="isLoading" :is-fixed="true" />
	</transition>
	<!-- /ローディング -->

	<!-- エラー処理 -->
	<transition name="fade">
		<common-error-alert @reload="registPoint" v-show="isError" />
	</transition>
	<!-- /エラー処理 -->

	<!-- ヘッダー -->
	<common-header
		:type="computedHeaderType.type"
		@click="onBack()"
	>
		<h1 class="common-header-title__heading">{{ computedHeaderType.title }}</h1>
	</common-header>
	<!-- /ヘッダー -->

	<!-- GPS -->
	<v-ons-list class="gps">
		<v-ons-list-header>現在位置から設定</v-ons-list-header>
		<v-ons-list-item tappable @click="getUserPosition">
			<p>現在地を取得する</p>
		</v-ons-list-item>
		<v-ons-list-item class="gps__explanation">
			<p>位置情報は気圧グラフを表示する地点と今日明日の気圧予報を表示する地点に利用します。</p>
			<p>許諾画面で「今後表示しない」の項目が表示される場合チェックを入れて許可すると、以後都度の許諾は省略され、位置情報が取得される状態が続きます。</p>
			<p>位置情報の取得を無効にする場合は、設定の「GPS登録」を押下することで無効となります。</p>
		</v-ons-list-item>
	</v-ons-list>
	<!-- /GPS -->

	<!-- 都道府県リスト -->
	<v-ons-list v-if="!municipalList" class="list">
		<v-ons-list-header>地域名から設定</v-ons-list-header>
		<v-ons-list-item
			v-for="(prefecture, index) in computedPrefecturesList"
			:key="index"
			@click="setPrefectures(prefecture.code)"
			:class="registedMunicipalData.prefecture_code === prefecture.code ? 'list-item--checked' : 'list-item--chevron'"
			tappable
		>
			<p>{{ prefecture.name }}</p>
		</v-ons-list-item>
	</v-ons-list>
	<!-- /都道府県リスト -->

	<!-- 市町村リスト -->
	<v-ons-list v-else class="list">
		<v-ons-list-item
			v-for="(municipal, index) in computedMunicipalList"
			:key="index"
			@click="setMunicipal(municipal.code)"
			:class="{ 'list-item--checked': cityCode === municipal.code }"
			tappable
		>
			<p>{{ municipal.name }}</p>
		</v-ons-list-item>
	</v-ons-list>
	<!-- /市町村リスト -->

	<!-- OnsenUIのMaterial Alert Dialog -->
	<v-ons-alert-dialog
		modifier="rowfooter"
		:visible.sync="isAlertDialog1Visible"
	>
		<span slot="title">{{ computedMunicipalName }}</span>
		<div class="alert-dialog-content">選択した地域を登録しますか？</div>
		<template slot="footer">
			<v-ons-alert-dialog-button @click="isAlertDialog1Visible = false">いいえ</v-ons-alert-dialog-button>
			<v-ons-alert-dialog-button @click="registPoint">はい</v-ons-alert-dialog-button>
		</template>
	</v-ons-alert-dialog>
	<!-- /OnsenUIのMaterial Alert Dialog -->
</ons-page>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA, SET_USER_CITY_CODE, SET_S3_CITY_CODE_DATA } from '../store/modules/common/mutation-types'

// Utils
import MunicipalCodeUtil from '@/utils/municipal-code-util'
import cmnConst from '@/assets/js/constant.js'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'
import CommonErrorAlert from '../components/Molecules/CommonErrorAlert'
import RedrawProgress from '../components/Molecules/RedrawProgress'

// ライブラリ
import axios from 'axios'

export default {
	name: 'PointRegistration',
	components: {
		CommonHeader,
		CommonErrorAlert,
		RedrawProgress
	},
	data () {
		return {
			cityCode: null,
			registedMunicipalData: {	// NOTE: 登録済み市町村データ。マウント時のエラー回避のため都道府県、市町村のkeyをあらかじめ用意し0で初期化しておく。
				prefecture_code: 0,
				code: 0
			},
			municipalList: null,
			isAlertDialog1Visible: false,
			isError: false,
			isLoading: false,
			isFirst: false,
			municipalCodeUtil: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData', 's3CityCodeData']),

		/**
		 * 都道府県リスト表示用
		 */
		computedPrefecturesList () {
			return this.municipalCodeUtil ? this.municipalCodeUtil.getPrefecturesList() : []
		},

		/**
		 * 市町村リスト表示用
		 */
		computedMunicipalList () {
			return this.municipalList
		},

		/**
		 * 選択した市町村名の表示用
		 */
		computedMunicipalName () {
			return this.cityCode && this.municipalCodeUtil ? this.municipalCodeUtil.getMunicipalData(this.cityCode).name : ''
		},

		/**
		 * ヘッダーボタン表示用
		 */
		computedHeaderType () {
			return this.municipalList ? { type: 'allow', title: '' } : { type: 'close', title: '地点登録' }
		}
	},
	mounted () {
		// 初回かどうかのフラグ
		this.isFirst = this.$route.query.isFirst ? this.$route.query.isFirst : false

		// コンテンツの高さをemitする。
		const containerHeight = window.innerHeight - cmnConst.HEADER_HEIGHT - cmnConst.SUGOTOKU_HEADER_HEIGHT - cmnConst.SUGOTOKU_FOOTER_HEIGHT // NOTE: 画面縦幅 - ヘッダー - スゴ得ヘッダー・フッター
		this.$emit('containerHeight', containerHeight)

		const apiArray = []

		// CHANGE: 変数に定義した時点でAPIリクエストが走ってしまう。リクエストを減らしたいので変数に定義せず配列に直接Pushする。
		// データを持ってなければPromise.allするAPI配列に追加
		if (!this.userData) {
			apiArray.push(this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}))
		}
		if (!this.s3CityCodeData) apiArray.push(this.SET_S3_CITY_CODE_DATA())

		// リクエストするAPIがあればリクエストして次の処理に、なければそのまま次の処理に進む
		if (apiArray.length > 0) {
			// ローディング処理
			this.isLoading = true
			Promise.all(apiArray).then(res => {
				// console.log('request userData: ', this.userData)
				// console.log('request s3CityCodeData: ', this.s3CityCodeData)
				this.municipalCodeUtil = new MunicipalCodeUtil(this.s3CityCodeData)
				this.cityCode = this.userData.city_code
				if (this.cityCode && this.cityCode) this.registedMunicipalData = this.municipalCodeUtil.getMunicipalData(this.cityCode)
				// console.log('cityCode: ', this.cityCode)
				// console.log('registedMunicipalData: ', this.registedMunicipalData)
			}).catch(error => {
				console.log(error)
				// NOTE: ユーザー情報が取れない=APIが叩けないので再読み込み画面を表示するのではなく、エラーページに遷移させる
				this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=error`, type: 'user' } })
			}).finally(() => {
				this.isLoading = false
			})
		} else {
			// console.log('registed userData: ', this.userData)
			// console.log('registed s3CityCodeData: ', this.s3CityCodeData)
			this.municipalCodeUtil = new MunicipalCodeUtil(this.s3CityCodeData)
			this.cityCode = this.userData.city_code
			if (this.cityCode && this.cityCode) this.registedMunicipalData = this.municipalCodeUtil.getMunicipalData(this.cityCode)
		}
	},
	beforeDestroy () {
		// コンテンツの高さをautoでemitする。
		this.$emit('containerHeight', 'auto')
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA, SET_USER_CITY_CODE, SET_S3_CITY_CODE_DATA]),

		/**
		 * 都道府県をクリックした時の処理
		 */
		setPrefectures (code) {
			this.municipalList = this.municipalCodeUtil.getMunicipalList(code).municipals ? this.municipalCodeUtil.getMunicipalList(code).municipals : null
		},

		/**
		 * 市町村をクリックした時の処理
		 */
		setMunicipal (code) {
			this.cityCode = code
			this.isAlertDialog1Visible = true
		},

		/**
		 * 地点登録API通信処理
		 */
		registPoint () {
			// ダイアログを閉じる
			this.isAlertDialog1Visible = false

			// ローディング処理
			this.isLoading = true

			// APIリクエスト
			this.SET_USER_CITY_CODE({
				user_id: this.userId,
				user_token: this.userToken,
				city_code: this.cityCode
			}).then(res => {
				console.log(res)
				if (res.data.result_code === 4001 || res.data.result_code === 4004) {
					this.$ons.notification.alert({ message: res.data.result_message, title: '' })
				} else {
					// CHANGE: 初回ならグラフページへ、それ以外はrouter.back
					this.isFirst ? this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=weatherGraph` } }) : this.$router.back()
				}
			}).catch(error => {
				console.log(error)
				// エラー処理
				this.isError = true
			}).finally(() => {
				this.isLoading = false
			})
		},

		/**
		 * ヘッダーの戻るボタンを押した時の処理
		 */
		onBack () {
			if (this.municipalList) {
				this.municipalList = null
			} else {
				this.$router.back()
			}
		},

		/**
		 * 現在位置を取得するボタンを押した時の処理
		 */
		getUserPosition () {
			// 非対応端末
			if (!navigator.geolocation) {
				this.$ons.notification.alert({ messageHTML: '<p style="text-align: left;">位置情報を取得できません。<br>端末の設定画面より位置情報取得を許可してください</p>', title: '' })
			}
			const gpsPosition = {
				latitude: null,
				longitude: null
			}
			const option = {
				enableHighAccuracy: true,
				timeout: 3000,
				maximumAge: 3000	// キャッシュされた位置の最大寿命ミリ秒
			}
			const success = position => {
				gpsPosition.latitude = position.coords.latitude
				gpsPosition.longitude = position.coords.longitude

				console.log(gpsPosition.latitude, gpsPosition.longitude)
				// TODO: 緯度・経度から地点コードを取得するAPI通信の実装
				// NOTE: storeにデータを保存する必要がないため直接リクエストする（今後、必要ならstoreに移動）
				axios.request({
					method: 'get',
					url: `${cmnConst.API_URL}/api/getcitycodebylatlng.json`,
					params: {
						user_id: this.userId,
						user_token: this.userToken,
						lat: gpsPosition.latitude,
						lng: gpsPosition.longitude
					}
				}).then(res => {
					if (res.data.result_code === 4000 || res.data.result_code === 4001) {	// 4000エラー（？）、4001エラー（パラメータ系エラー）
						console.log('4000 or 4001 error: ', res)
						this.$ons.notification.alert({ message: `位置情報を取得できません。<br>${res.data.result_message}。`, title: '' })
					} else if (res.data.records.city_code) {	// データ取得OK
						// 地点コードを取得したらthis.cityCodeに保存してthis.registPointを呼ぶ
						this.cityCode = res.data.records.city_code
						console.log('GPS cityCode: ', this.cityCode)
						this.registPoint()
					} else {	// それ以外のエラー
						console.log('Unknown error: ', res)
						this.$ons.notification.alert({ message: `位置情報を取得できません。`, title: '' })
					}
				}).catch(error => {
					console.log('error: ', error)
					this.$ons.notification.alert({ message: `位置情報を取得できません。`, title: '' })
				})
			}
			const error = error => {
				console.log('getUserPosition Error: ', error)
				// ブラウザのGPSを利用するプロンプトで許可しなかった場合
				this.$ons.notification.alert({ messageHTML: '<p style="text-align: left;">位置情報を取得できません。<br>端末の設定画面より位置情報取得を許可してください</p>', title: '' })
				// TODO: ユーザーが許可しない場合とGPS無効を出しわける場合（OSによって動作が異なるよう。要検証。）
				// if (error.message !== 'User denied Geolocation') {
				// 	this.$ons.notification.alert({ messageHTML: '<p style="text-align: left;">位置情報を取得できません。<br>端末の設定画面より位置情報取得を許可してください</p>', title: '' })
				// }
			}
			navigator.geolocation.getCurrentPosition(success, error, option)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";
// TODO: デザイン調整（チェックマークの色をzutoolgreenに、ダイアログ）

.point-registration {
	width: 100%;
	height: 100%;
	overflow: hidden;

	/deep/ .page__content {
		overflow-y: scroll !important;
	}

	/deep/ .list-item {
		padding: 0 0 0 $spacing-10;
	}

	/deep/ .list-item__center {
		padding: $spacing-16 $spacing-10 $spacing-16 0;
		p { margin: 0; }
	}

	/deep/ .common-error-alert {
		position: fixed;
	}

	/deep/ .list-header--material {
		font-size: $font-size-12;
		background-color: $background-list;
	}

	.list-item--checked {
		&:before {
			content: '';
			position: absolute;
			right: $spacing-16;
			top: 50%;
			width: 10px;
			height: 4px;
			background: transparent;
			border: 2px solid $checkmark-primary;
			border-top: none;
			border-right: none;
			transform: translateY(-50%) rotate(-45deg);
		}
	}

	.gps {
		&__explanation {
			font-size: $font-size-12;

			& p {
				position: relative;
				padding-left: $spacing-16;

				&::before {
					content: '※';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}
</style>
